import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ContentS } from '@imus/base-ui';
import {
  LoadingIndicator,
  PlayPauseButton,
  SelectAudioFileContext,
} from 'imdshared';

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
`;

const MasteringAudioTrackBase = ({
  title,
  style,
  subtitle,
  loading,
  className,
  fileUrl,
  primary,
  trackId,
}: {
  trackId?: number | string;
  fileUrl?: string;
  title?: string;
  subtitle?: string;
  loading?: string | boolean;
  primary?: boolean;
  style?: React.CSSProperties;
  className?: string;
}) => {
  return (
    <div style={style} className={className}>
      {loading ? (
        <LoadingIndicator
          color={primary ? 'var(--accent)' : 'var(--on-surface)'}
        />
      ) : (
        <>
          {fileUrl && (
            <SelectAudioFileContext.Provider value={() => fileUrl}>
              <PlayPauseButton
                css={
                  primary
                    ? css`
                        --on-surface: var(--accent);
                      `
                    : undefined
                }
                inline
                trackToBePlayed={trackId}
                fileUrlToBePlayed={fileUrl}
              />
            </SelectAudioFileContext.Provider>
          )}
          {!fileUrl && <PlayPauseButton inline trackToBePlayed={trackId} />}
        </>
      )}
      <TextWrapper>
        <ContentS>{title}</ContentS>
        {subtitle && (
          <ContentS style={{ marginLeft: 'auto' }} secondary>
            {subtitle}
          </ContentS>
        )}
      </TextWrapper>
    </div>
  );
};

export const MasteringTrackAudio = styled(MasteringAudioTrackBase)`
  display: flex;
  height: 40px;
  padding: 8px 16px;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
  border-radius: 20px;
  background: ${({ primary, loading }) =>
    primary || loading ? 'var(--accent-semi)' : 'var(--on-surface-semi)'};
`;
