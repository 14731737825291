import { css } from '@emotion/react';
import { UploadIcon, ContentM, PlusIcon } from '@imus/base-ui';
import { TracksUploader } from 'imdshared';
import { Card, Button } from 'imdui';
import { useTranslation } from 'react-i18next';

export const TrackUploadCard = ({
  onSelect,
  onOpenTracksBrowser,
  style,
  className,
}: {
  onOpenTracksBrowser: () => void;
  style?: React.CSSProperties;
  className?: string;
  onSelect: (ids: (number | string)[]) => void;
}) => {
  const { t } = useTranslation();
  return (
    <Card
      style={style}
      className={className}
      css={css`
        display: flex;
        flex-direction: column;
        padding: 32px;
      `}
    >
      <Card
        secondary
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 64px;
          padding: 64px;
          border-radius: 8px;
          flex: 1 0 0;
          align-self: stretch;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 16px;
            flex: 1 0 0;
            align-self: stretch;
          `}
        >
          <UploadIcon
            css={css`
              width: 144px !important;
              height: 144px !important;
            `}
          />
          <ContentM secondary>
            {t('drag-and-drop-tracks-mastering-subtitle')}
          </ContentM>
        </div>
        <div
          css={css`
            background: var(--outline-var, #4c4c4c);
            height: 1px;
            width: 100%;
          `}
        />

        <div
          css={css`
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            gap: 8px;
            align-self: stretch;
            flex-wrap: wrap;
          `}
        >
          <Button
            primary
            text={t('import-from-library')}
            iconLeft={PlusIcon}
            onClick={onOpenTracksBrowser}
          />
          <TracksUploader
            onAddTempTracks={(tempIds) => {
              onSelect(tempIds);
            }}
          >
            <Button
              primary
              text={t('upload-tracks')}
              iconLeft={UploadIcon}
              onClick={() => {
                //
              }}
            />
          </TracksUploader>
        </div>
      </Card>
    </Card>
  );
};
