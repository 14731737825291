import { useCallback, useMemo, useState } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Redirect, useLocation } from 'react-router-dom';
import { ProductPageContext } from 'components';
import NavBar from '../../shared/NavBar';
import type { ProductPageContextType } from '../../shared/ProductWizard';
import { ProductPriceContext, ProductScreen } from '../../shared';
import { TrackMasteringForm } from './TrackMasteringForm';
import { useOverview } from 'imddata';
import type { ProductPriceContextType } from 'screens/Order/shared/ProductPriceContext';

const MASTER = 'preview';

const InstantMasteringManager = () => {
  return null;
};

const INSTANT_MASTERING_STEPS = [{ id: MASTER, to: MASTER, label: 'release' }];

export const INSTANT_MASTERING_CONTEXT: Omit<ProductPageContextType, 'id'> = {
  product: 'instant-mastering',
  title: 'instant-mastering',
  initialData: { tracks: [] },
  steps: INSTANT_MASTERING_STEPS,
  initialStepsState: INSTANT_MASTERING_STEPS,
  entity: 'masteringTracks',
  overviewEntity: 'masteringTracksOverview',
  StepperStateManager: InstantMasteringManager,
  NavBarRenderer: function NavBarRenderer({ stepId, ...props }) {
    switch (stepId) {
      default:
        return <NavBar {...props} />;
    }
  },
  RedirectManager: function RedirectManager({ match }) {
    return <Redirect to={`${match.url}/${MASTER}`} />;
  },
  StepRenderer: function StepsRenderer({ stepId }) {
    // const id = useProductId();

    switch (stepId) {
      default:
        return <span>{`${stepId} :not-found`}</span>;
    }
  },
};

const noPrice = {
  error: true,
  calculating: false,
  recalculate: () => {
    throw new Error('should not be called');
  },
};

type LocationState = {
  tracks?: number[];
};

export function InstantMastering(_props: RouteComponentProps) {
  const { state } = useLocation<LocationState>();

  const [selectedVersions, setSelectedVersions] = useState<string[]>([]);
  const overviewData = useMemo(
    () => ({
      masteringTrackId: selectedVersions,
    }),
    [selectedVersions]
  );

  const { overview, request } = useOverview({
    entity: 'masteringTracksOverview',
    data: overviewData,
  });

  const handleSelectVersions = useCallback((id: string, checked: boolean) => {
    setSelectedVersions((versionsState) => {
      if (checked && !selectedVersions.includes(id)) {
        return [...versionsState, id];
      }
      if (!checked) {
        return versionsState.filter((v) => v !== id);
      }
      return versionsState;
    });
  }, []);

  const priceContext = useMemo<ProductPriceContextType>(() => {
    if (!selectedVersions.length) return noPrice;
    return {
      price: overview?.total,
      priceCurrency: overview?.currency.id,
      calculating: !!request.loading,
      recalculate: () => {
        //
      },
    };
  }, [overview, selectedVersions]);

  const value = useMemo(() => {
    return {
      id: 'mastering',
      ...INSTANT_MASTERING_CONTEXT,
    };
  }, []);

  return (
    <ProductPageContext.Provider value={value}>
      <ProductPriceContext.Provider value={priceContext}>
        <ProductScreen title="mastering">
          <TrackMasteringForm
            tracks={state?.tracks}
            selectedVersions={selectedVersions}
            onSelectVersion={handleSelectVersions}
          />
        </ProductScreen>
      </ProductPriceContext.Provider>
    </ProductPageContext.Provider>
  );
}
