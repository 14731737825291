import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Body, BodySmall, Caption, RadioButton } from 'imdui';

interface Props {
  url: string;
  name: string;
  lastAlbum?: {
    name: string;
    images: {
      width: number;
      url: string;
    }[];
  };
  selected?: boolean;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  style: React.CSSProperties;
}

const InsideWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 0;
  cursor: pointer;
`;

const Container = styled.div`
  padding: 0 24px;

  :not(:last-child) {
    ${InsideWrapper} {
      box-shadow: inset 0 -1px 0 0 var(--outline-var);
    }
  }
`;

const ArtistImage = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 16px;
`;

const ArtistLetter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: var(--surface-container-medium-fx);
  font-weight: 500;
  text-align: center;
  margin-right: 16px;
`;

const LinkToArtist = styled.a`
  color: inherit;
  display: inline;
`;

const TextColumn = styled.div`
  flex: 1;
  min-width: 0;
  margin-right: 16px;

  ${Body} {
    font-weight: 500;
  }

  ${Caption} {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--on-surface-var);
  }
`;

const IndicatorRow = styled.div`
  display: flex;
  align-items: center;

  > ${BodySmall} {
    margin-right: 8px;
  }
`;

export default function ArtistListItem({
  url,
  name,
  lastAlbum,
  selected,
  onClick,
  style,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const image = lastAlbum?.images.find(
    (img) => img.width >= 64 && img.width <= 300
  );

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>): void => {
      onClick(e);
    },
    [onClick]
  );

  const handleLinkClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>): void => {
      e.stopPropagation();
    },
    []
  );

  return (
    <Container onClick={handleClick} style={style}>
      <InsideWrapper>
        {image ? <ArtistImage src={image.url} /> : null}

        {!lastAlbum || !image ? <ArtistLetter>{name[0]}</ArtistLetter> : null}

        <TextColumn>
          <LinkToArtist
            href={url}
            target="_blank"
            rel="noopener"
            onClick={handleLinkClick}
          >
            <Body>{name}</Body>
          </LinkToArtist>
          {lastAlbum ? <Caption>{lastAlbum.name}</Caption> : null}
        </TextColumn>

        <IndicatorRow>
          {selected && <BodySmall>{t('change')}</BodySmall>}
          <RadioButton selected={!!selected} />
        </IndicatorRow>
      </InsideWrapper>
    </Container>
  );
}
