import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ChevronLeftIcon, PlusIcon } from '@imus/base-ui';
import { Button, Window } from 'imdui';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
  MasteringTrack,
  MasteringTrackList,
  MasteringTrackSection,
} from './MasteringTrackList';
import {
  useFileUploadRequestProvider,
  useIsUploadingTracks,
  useTracks,
} from 'imddata';
import { MasteringStyleSelectorModal } from './MasteringStyleSelectorModal';
import { TrackUploadCard } from './TrackUploadCard';
import { MASTERING_STEP } from './constants';
import { MasteringStepper } from './MasteringStepper';
import { MasteringTrackVersions } from './MasteringTrackVersions';
import {
  TracksBrowserDialog,
  useProductPage,
  useProductPageActions,
} from 'components';
import { useMasteringOrder } from './useMasteringOrder';

const FormLayout = styled.div`
  display: flex;
  margin: 16px 0;
  align-items: flex-start;
  gap: 32px;
  flex: 1 0 0;
  align-self: stretch;
`;

// TODO add handling of Upload errors
const MasteringTrackConnected = ({
  id,
  ...props
}: React.ComponentProps<typeof MasteringTrack> & { id: string | number }) => {
  const { t } = useTranslation();
  const fileUpload = useFileUploadRequestProvider({
    entity: 'tracks',
    id,
  });

  return (
    <MasteringTrack
      {...props}
      loading={fileUpload.uploading ? t('uploading') : false}
    />
  );
};

const FORM_STEPS = [
  MASTERING_STEP.GENERATE_PREVIEWS,
  MASTERING_STEP.COMPARE,
  MASTERING_STEP.CHOOSE_TRACKS,
];

// TODO
// - handle Upload errors from API
// - Remove paid versions from the list to avoid selecting them for payment
// - 99 tracks limit
// - Add restart timeout
export const TrackMasteringForm = (props: {
  selectedVersions: string[];
  onSelectVersion: (id: string, checked: boolean) => void;
  tracks?: number[];
}) => {
  const { t } = useTranslation();
  const [openAddTracksModal, setOpenAddTracksModal] = useState(false);

  const productState = useProductPage();
  const { changeProduct } = useProductPageActions();

  const [trackIds, setTrackIds] = useState<(string | number)[]>(() => {
    return (props.tracks || productState.state.data.trackIds || []) as (
      | string
      | number
    )[];
  });

  const [step, setStep] = useState<MASTERING_STEP>(
    () => MASTERING_STEP.UPLOAD_TRACKS
  );

  const [openStyleSelector, setOpenStyleSelector] = useState(false);
  const [globalAudioReferenceId, setGlobalAudioReferenceId] =
    useState<string>();

  const { entries: tracks, request } = useTracks({ ids: trackIds });
  useEffect(() => {
    if (!trackIds.length) {
      setStep(MASTERING_STEP.UPLOAD_TRACKS);
      return;
    }
    if (step === MASTERING_STEP.UPLOAD_TRACKS) {
      setStep(MASTERING_STEP.GENERATE_PREVIEWS);
    }
  }, [trackIds]);

  const isUploadingTracks = useIsUploadingTracks({ ids: trackIds });
  const loading =
    isUploadingTracks ||
    request.loading ||
    !!tracks.find((track) => track.temporary);

  useEffect(() => {
    if (tracks.length > 0) {
      setTrackIds(tracks.map((t) => t.id));
    }
    changeProduct({ trackIds: tracks.map((t) => t.id) });
  }, [tracks.map((t) => t.id).join('')]);

  const { startOrder, ordering, ordered } = useMasteringOrder({
    masteredTrackIds: props.selectedVersions,
  });

  useEffect(() => {
    changeProduct({ trackIds: [] });
  }, [ordered]);

  const [openSelector, setOpenSelector] = useState(false);

  return (
    <FormLayout>
      <div
        css={css`
          flex: 312px;
          max-width: 312px;
          display: flex;
          flex-direction: column;
          gap: 16px;
          @media (max-width: 1024px) {
            display: none;
          }
        `}
      >
        <MasteringStepper step={step} loading={loading || isUploadingTracks} />
      </div>
      {step === MASTERING_STEP.UPLOAD_TRACKS && (
        <TrackUploadCard
          css={css`
            height: calc(100vh - 120px);
            width: 100%;
          `}
          onOpenTracksBrowser={() => {
            setOpenSelector(true);
            setOpenAddTracksModal(false);
          }}
          onSelect={(ids) => {
            setOpenAddTracksModal(false);
            setTrackIds([...trackIds, ...ids]);
          }}
        />
      )}
      {FORM_STEPS.includes(step) && (
        <MasteringTrackList
          actions={
            <>
              {step === MASTERING_STEP.GENERATE_PREVIEWS && (
                <>
                  <Button
                    text={t('add-tracks')}
                    iconLeft={PlusIcon}
                    appearance="fill"
                    onClick={() => setOpenAddTracksModal(true)}
                  />
                  <Button
                    primary
                    appearance="fill"
                    disabled={loading}
                    text={t('continue')}
                    onClick={() => setOpenStyleSelector(true)}
                  />
                </>
              )}
              {step === MASTERING_STEP.COMPARE && (
                <>
                  <Button
                    text={t('back')}
                    iconLeft={ChevronLeftIcon}
                    appearance="fill"
                    onClick={() => setStep(MASTERING_STEP.GENERATE_PREVIEWS)}
                  />
                  <Button
                    primary
                    appearance="fill"
                    disabled={loading}
                    text={t('select-tracks', { context: 'mastering' })}
                    onClick={() => setStep(MASTERING_STEP.CHOOSE_TRACKS)}
                  />
                </>
              )}
              {step === MASTERING_STEP.CHOOSE_TRACKS && (
                <>
                  <Button
                    text={t('back')}
                    iconLeft={ChevronLeftIcon}
                    appearance="fill"
                    onClick={() => setStep(MASTERING_STEP.COMPARE)}
                  />
                  <Button
                    primary={props.selectedVersions.length > 0}
                    appearance="fill"
                    disabled={
                      loading || ordering || props.selectedVersions.length === 0
                    }
                    text={t('order-mastering', {
                      count: props.selectedVersions.length,
                      defaultValue: 'Purchase {{count}} tracks',
                    })}
                    onClick={() => {
                      startOrder();
                    }}
                  />
                </>
              )}
            </>
          }
        >
          {tracks.map((track, idx) => {
            return (
              <MasteringTrackSection key={track.id}>
                <MasteringTrackConnected
                  id={track.id}
                  onRemove={
                    track.temporary
                      ? undefined
                      : () =>
                        setTrackIds(trackIds.filter((id) => id !== track.id))
                  }
                  title={track.defaultName.title}
                  index={idx + 1}
                />
                {!track.temporary && (
                  <MasteringTrackVersions
                    style={
                      step !== MASTERING_STEP.COMPARE &&
                        step !== MASTERING_STEP.CHOOSE_TRACKS
                        ? { display: 'none' }
                        : undefined
                    }
                    onSelect={
                      step === MASTERING_STEP.CHOOSE_TRACKS
                        ? props.onSelectVersion
                        : undefined
                    }
                    selected={props.selectedVersions}
                    originalAudioId={track.fileId}
                    version={globalAudioReferenceId}
                    trackId={track.id}
                  />
                )}
              </MasteringTrackSection>
            );
          })}
        </MasteringTrackList>
      )}
      {step === MASTERING_STEP.GENERATE_PREVIEWS && (
        <MasteringStyleSelectorModal
          onSelect={(style) => {
            setStep(MASTERING_STEP.COMPARE);
            setOpenStyleSelector(false);
            setGlobalAudioReferenceId(style);
          }}
          open={openStyleSelector}
          onRequestClose={() => {
            setOpenStyleSelector(false);
          }}
        />
      )}
      <Window
        title={t('add-tracks')}
        isOpen={openAddTracksModal}
        close={() => setOpenAddTracksModal(false)}
      >
        <TrackUploadCard
          css={css`
            min-height: 512px;
          `}
          onOpenTracksBrowser={() => {
            setOpenSelector(true);
            setOpenAddTracksModal(false);
          }}
          onSelect={(ids) => {
            setOpenAddTracksModal(false);
            setTrackIds([...trackIds, ...ids]);
          }}
        />
      </Window>

      <TracksBrowserDialog
        isOpen={openSelector}
        isDisabled={(t) => !t.hasAudioUploaded}
        disabledTracks={trackIds}
        onClose={() => {
          setOpenSelector(false);
        }}
        onTracksSelected={(ids) => {
          setTrackIds([...trackIds, ...ids]);
          setOpenSelector(false);
        }}
      />
    </FormLayout>
  );
};
