import styled from '@emotion/styled';
import React, { forwardRef } from 'react';
import { LoadingIndicator } from '../../base';
import { SVGIcon } from '../../icon';
import Clickable from '../Clickable';
import { ButtonText } from '@imus/base-ui';

const Text = styled(ButtonText)`
  align-items: center;
  display: flex;
  gap: 4px;
`;
const LoadingIndicatorStyled = styled(LoadingIndicator)`
  flex-shrink: 0;
`;

export const ClickableButton = styled(Clickable) <{
  primary: boolean | 'paid' | 'error' | 'black';
  appearance: 'stroke' | 'fill' | 'transparent' | 'elevated';

  size: 'large' | 'small' | 'medium';
  icon?: boolean;
  hasText?: boolean;
  position: 'center' | 'left';
}>`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  justify-content: ${({ position, icon, size }) =>
    size === 'small' || !icon ? 'center' : position};
  min-height: ${({ size, hasText }) =>
    hasText && size === 'large'
      ? '48px'
      : hasText && size === 'medium'
        ? '40px'
        : '32px'};
  border-radius: ${({ size }) =>
    size === 'large' ? '24px' : size === 'medium' ? '20px' : '16px'};
  transition: all 0.1s;
  padding: ${({ hasText }) => (hasText ? '0 16px' : '')};
  width: ${({ hasText }) => (hasText ? 'auto' : '32px')};

  background: ${({ appearance, primary }) => {
    if (appearance === 'transparent') {
      return 'transparent';
    }
    if (appearance === 'elevated') {
      return 'var(--on-surface-semi)';
    }
    if (appearance === 'stroke') {
      if (primary) {
        return 'var(--accent-container)';
      }
      return 'var(--surface-container-high, #fff)';
    }

    switch (primary) {
      case 'error':
        return 'var(--state-negative)';
      case 'black':
      case 'paid':
      case true:
        return 'var(--accent, #4FC100)';
      default:
        return 'var(--state-active-on-surface, rgba(27, 28, 27, 0.10))';
    }
  }};

  border: ${({ appearance }) => (appearance === 'stroke' ? '1px solid' : 'none')};

  ${Text} {
    opacity: ${({ disabled, appearance }) => (disabled ? (appearance === 'stroke' ? 0.3 : 0.3) : 1)};
  }

  border-color: ${({ primary, disabled }) => {
    if (disabled) {
      return 'var(--outline-var, #C9C6C4)';
    }
    switch (primary) {
      case 'error':
        return 'var(--state-negative)';
      case 'black':
      case 'paid':
      case true:
        return 'var(--accent, #4FC100)';
      default:
        return 'var(--outline-var, #C9C6C4)';
    }
  }};
  overflow: hidden;

  & > svg {
    width: 24px;
  opacity: ${({ disabled, appearance }) => (disabled ? (appearance === 'stroke' ? 0.3 : 0.3) : 1)};
  }
  & > svg path {
 ${({ appearance, primary }) =>
    appearance === 'fill' && primary
      ? '--on-surface: var(--on-accent, #FEFFFE)'
      : ''};
    };
  }
`;
const RightIcon = styled(SVGIcon)`
  flex-shrink: 0;
  width: auto;
  margin-left: auto;
`;

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export type ButtonProps = {
  text?: string | React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  // icons
  icon?:
  | string
  | React.FC<{ style?: React.CSSProperties; fill: string }>
  | React.VFC<React.SVGProps<SVGSVGElement>>;
  iconLeft?: string | React.FC<{ style?: React.CSSProperties; fill?: string }>;
  iconProps?: {
    style?: React.CSSProperties;
    color?: string;
    size?: number;
  };
  // other props
  type?: string;
  testId?: string;
  value?: any;
  name?: string;
  autoFocus?: boolean;
  container?: string;
  showLoading?: boolean;
  disabled?: boolean;
  size?: 'medium' | 'small' | 'large';
  position?: 'left' | 'center';
  children?: React.ReactNode;
  appearance?: 'stroke' | 'fill' | 'transparent' | 'elevated';
  primary?: boolean | 'paid' | 'error' | 'black';
  className?: string;
  focused?: boolean;
  style?: any;
};

const Button = forwardRef((props: ButtonProps, ref) => {
  const {
    text = undefined,
    onClick,
    // icons
    icon = null,
    iconLeft = null,
    iconProps = { style: {} },
    // other props
    type,
    testId,
    appearance = 'stroke',
    position = 'center',
    size = 'medium',
    autoFocus = false,
    showLoading = false,
    disabled = false,
    children = null,
    primary = false,
    value,
    name,
    // style
    className,
    style,
  } = props;
  return (
    <ClickableButton
      value={value}
      name={name}
      style={style}
      ref={ref}
      size={size}
      type={type}
      hasText={!!text}
      primary={primary}
      position={position}
      appearance={appearance}
      disabled={disabled}
      className={className}
      testId={testId}
      onClick={onClick}
      autoFocus={autoFocus}
      icon={!!(iconLeft || icon)}
    >
      {iconLeft && typeof iconLeft === 'string' && (
        <SVGIcon
          d={iconLeft}
          {...iconProps}
          style={{
            ...iconProps.style,
          }}
        />
      )}
      {iconLeft &&
        typeof iconLeft === 'function' &&
        iconLeft({
          ...iconProps,
          style: {
            fill: '#333',
            ...iconProps.style,
          },
        })}

      {text !== undefined && (
        <Text
          appearance={primary ? appearance : 'stroke'}
          size={size}
          showLoading={showLoading}
        >
          {text}
        </Text>
      )}

      {icon && typeof icon === 'string' && !showLoading && (
        <RightIcon d={icon} {...iconProps} style={iconProps.style} />
      )}
      {icon &&
        typeof icon === 'function' &&
        !showLoading &&
        icon({
          ...iconProps,
          fill: 'var(--on-surface)',
          style: {
            ...iconProps.style,
          },
        })}

      <LoadingWrapper>
        {showLoading && (
          <LoadingIndicatorStyled
            color={
              appearance === 'fill' && primary
                ? 'var(--on-accent)'
                : 'var(--fg-1)'
            }
          />
        )}
      </LoadingWrapper>

      {children}
    </ClickableButton>
  );
});

Button.displayName = 'Button';

export default Button;
