import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Caption, ContentM, MinusIcon } from '@imus/base-ui';
import { Card, IconButton, LoadingIndicator } from 'imdui';
import { useTranslation } from 'react-i18next';

const Header = styled.div`
  display: flex;
  padding: 32px 32px 16px;
  align-items: center;
  position: sticky;
  gap: 16px;
  /* Background blur */
  backdrop-filter: blur(25px);
`;

const Footer = styled.div`
  display: flex;
  padding: 16px 32px 32px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;

  /* Background blur */
  background: var(--surface-container-medium-fx, rgba(40, 40, 40, 0.9));

  backdrop-filter: blur(25px);
  & > * {
    flex: 1;
  }
`;

const Wrapper = styled.div`
  display: flex;
  padding: 16px 32px;
  overflow-y: auto;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  height: 100%;
`;

const MasteringTrackBase = ({
  title,
  index,
  className,
  style,
  loading,
  onRemove,
}: {
  loading?: boolean | string;
  onRemove?: () => void;
  title: string;
  index: number;
  className?: string;
  style?: React.CSSProperties;
}) => {
  return (
    <div style={style} className={className}>
      <ContentM>{index}</ContentM>
      <ContentM
        style={{ flex: 1, display: 'flex', alignItems: 'center', gap: '16px' }}
      >
        {title}
        {loading && (
          <span
            style={{
              display: 'flex',

              alignItems: 'center',
              gap: '8px',
            }}
          >
            {typeof loading === 'string' ? (
              <Caption secondary>{loading}</Caption>
            ) : null}
            <LoadingIndicator color={'var(--on-surface-var)'} />
          </span>
        )}
      </ContentM>
      {onRemove && (
        <IconButton icon={MinusIcon} disabled={!!loading} onClick={onRemove} />
      )}
    </div>
  );
};

export const MasteringTrack = styled(MasteringTrackBase)`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;

export const MasteringTrackSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

export const MasteringTrackList = ({
  children,
  actions,
  className,
  style,
}: {
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
  actions: React.ReactNode;
}) => {
  const { t } = useTranslation();
  return (
    <Card
      css={css`
        display: flex;
        flex-direction: column;
        height: calc(100vh - 120px);
        width: 100%;
        -webkit-filter: blur(0px);
      `}
      className={className}
      style={style}
    >
      <Header>
        <ContentM># </ContentM>
        <ContentM>{t('track')}</ContentM>
      </Header>
      <Wrapper>{children}</Wrapper>
      <Footer>{actions}</Footer>
    </Card>
  );
};
