import type { EntityModels } from 'imddata';
import { useCreateOrder, useEntryProvider } from 'imddata';
import { useFastPaymentFlowContext } from 'imdshared';
import { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';

export const useMasteringOrder = ({
  masteredTrackIds,
}: {
  masteredTrackIds: string[];
}) => {
  const [processStarted, setStartOrder] = useState(false);

  const { open: openPayment } = useFastPaymentFlowContext();
  const history = useHistory();

  const startOrder = useCallback(() => {
    setStartOrder(true);
  }, []);

  const bundleId = masteredTrackIds.join(',');

  const {
    createOrder,
    request: { errorMessage, id: orderId },
  } = useCreateOrder({
    entity: 'masteringTracks',
    requestStoreKey: `${bundleId}`,
  });

  useEffect(() => {
    if (processStarted) {
      openPayment({
        loading: true,
      });

      createOrder({
        componentKey: bundleId,
        data: {
          masteringTrackId: masteredTrackIds,
        },
      });
    }
  }, [processStarted, masteredTrackIds]);

  const order = useEntryProvider<EntityModels.Order>({
    entity: 'orders',
    id: orderId,
  });

  useEffect(() => {
    if (errorMessage) {
      history.push('/dashboard');

      openPayment({
        loading: false,
        failed: true,
        successPath: `/order/instant-mastering/thank-you`,
      });
      return;
    }
    if (order) {
      openPayment({
        loading: false,
        orderStatus: order.status,
        successPath: `/order/instant-mastering/thank-you`,
      });
    }
  }, [errorMessage, order]);

  return {
    startOrder,
    ordered: !!order,
    ordering: processStarted,
  };
};
