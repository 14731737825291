import { createContext } from 'react';
import type { EntityModels } from 'imddata';

export type ProductPriceContextType = {
  price?: string;
  ampDiscount?: number;
  type?: string;
  priceCurrency?: string;
  priceFormat?: string;
  calculating: boolean;
  error?: string | boolean;
  overview?: EntityModels.Overview;
  recalculate: {
    (): void;
  };
};

const ProductPriceContext = createContext<ProductPriceContextType>({
  price: '0',
  priceFormat: '',
  priceCurrency: '',
  ampDiscount: undefined,
  calculating: false,
  error: true,
  recalculate: () => {
    return undefined;
  },
});

export default ProductPriceContext;
