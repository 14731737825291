import React, { useCallback, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'imdui';
import { Redirect } from 'react-router-dom';

type RenderActionType = React.FC<{
  onClick: () => void;
  text: string;
  iconProps?: Record<string, unknown>;
  icon?: any;
  primary?: boolean;
  style?: React.CSSProperties;
  disabled?: boolean;
}>;

type Props = {
  testId?: string;
  tracks?: Array<number>;
  onClick?: () => void;
  renderAction?: RenderActionType;
  disabled?: boolean;
  product: 'ycid' | 'md' | 'mastering' | 'instant-mastering';
  text: string;
  primary?: boolean;
  iconProps?: Record<string, unknown>;
  icon?: any;
  newIcon?: string;
  style?: React.CSSProperties;
  languageId?: string;
};

const defaultRenderAction: RenderActionType = (props) => <Button {...props} />;

export default function RedirectToDeliveryButton({
  tracks,
  languageId,
  onClick,
  renderAction = defaultRenderAction,
  product = 'md',
  ...props
}: Props): React.ReactElement {
  const [distributeClicked, setDistributionClicked] = useState(false);
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
    setDistributionClicked(true);
  }, [onClick]);

  return (
    <>
      {renderAction({
        onClick: handleClick,
        primary: true,
        ...props,
      })}

      {distributeClicked && (
        <Redirect
          key="redirect"
          to={{
            pathname: `/order/${product}/new`,
            state: {
              languageId,
              tracks,
            },
          }}
        />
      )}
    </>
  );
}

export const RedirectToMasteringButton = ({
  tracks,
  ...props
}: Omit<
  React.ComponentProps<typeof RedirectToDeliveryButton>,
  'product' | 'text' | 'icon'
>): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <RedirectToDeliveryButton
      {...props}
      product="instant-mastering"
      text={t('send-to-mastering')}
      tracks={tracks}
    />
  );
};
