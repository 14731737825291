import { css } from '@emotion/react';

export const baseColors = css`
  /* OLD */
  --black-95: rgba(0, 0, 0, 0.95);
  --black-65: rgba(0, 0, 0, 0.65);
  --black-35: rgba(0, 0, 0, 0.35);
  --black-10: rgba(0, 0, 0, 0.1);

  --white-95: rgba(255, 255, 255, 0.95);
  --white-65: rgba(255, 255, 255, 0.65);
  --white-35: rgba(255, 255, 255, 0.35);
  --white-10: rgba(255, 255, 255, 0.1);

  --gray-100: #ffffff;
  --gray-95: #fafafa;
  --gray-90: #f4f4f4;
  --gray-85: #eeeeee;
  --gray-33: #333333;
  --gray-20: #272727;
  --gray-15: #212121;
  --gray-10: #1a1a1a;

    --bonbon-08: #37001b;
  --bonbon-08-10: #37001b1a;
  --bonbon-08-50: #37001bbf;
  --bonbon-08-75: #37001bbf;
  --bonbon-60: var(--brand-rose-bonbon);
  --bonbon-60-10: #fe489d1a;
  --bonbon-60-15: #fe489d26;
  --bonbon-99: #fffbff;
  --bonbon-99-10: #fffbff1a;
  --brand-alabaster: #e8eddf;
  --brand-black: #0a0908;
  --brand-midnight-green: #004e64;
  --brand-rose-bonbon: #ff499e;
  --brand-screamin-green: #73fc8a;
  --brand-sunglow: #fdca40;
  --gray-08: #181818;
  --gray-08-10: #1818181a;
  --gray-08-15: #18181826;
  --gray-08-20: #18181833;
  --gray-08-50: #18181880;
  --gray-08-75: #181818bf;
  --gray-08-90: #181818e5;
  --gray-11: #1d1d1d;
  --gray-16: #282828;
  --gray-16-90: #282828e5;
  --gray-24: #383838;
  --gray-24-90: #383838e5;
  --gray-84: #d1d1d1;
  --gray-84-90: #d1d1d1e5;
  --gray-91: #e5e5e5;
  --gray-96: #f3f3f3;
  --gray-96-90: #f3f3f3e5;
  --gray-99: #fcfcfc;
  --gray-99-10: #fcfcfc1a;
  --gray-99-15: #fcfcfc26;
  --gray-99-20: #fcfcfc33;
  --gray-99-50: #fcfcfc80;
  --gray-99-75: #fcfcfcbf;
  --gray-99-90: #fcfcfce5;
  --midnight-green-08: #001b23;
  --midnight-green-08-10: #001b231a;
  --midnight-green-08-50: #001b2380;
  --midnight-green-08-75: #001b23bf;
  --midnight-green-30: var(--brand-midnight-green);
  --midnight-green-30-10: #004d631a;
  --midnight-green-30-15: #004d6326;
  --midnight-green-99: #fafcff;
  --midnight-green-99-10: #fafcff1a;
  --red-orange-10: #410002;
  --red-orange-10-10: #4100021a;
  --red-orange-50: #e32e2a;
  --red-orange-60: #ff5449;
  --red-orange-99: #fffbff;
  --red-orange-99-10: #fffbff1a;
  --screamin-green-08: #001d06;
  --screamin-green-08-10: #001d061a;
  --screamin-green-90: var(--brand-screamin-green);
  --screamin-green-90-10: #76fe8b1a;
  --screamin-green-90-15: #76fe8b26;
  --screamin-green-99: #f6fff1;
  --screamin-green-99-10: #f6fff11a;
  --screamin-green-99-50: #f6fff180;
  --screamin-green-99-75: #f6fff1bf;
  --sunglow-08: #201600;
  --sunglow-08-10: #2016001a;
  --sunglow-82: var(--brand-sunglow);
  --sunglow-82-10: #fcc30f1a;
  --sunglow-82-15: #fcc30f26;
  --sunglow-99: #fffbff;
  --sunglow-99-10: #fffbff1a;
  --sunglow-99-50: #fffbff80;
  --sunglow-99-75: #fffbffbf;
`;

export const DARK_MODE_COLORS = css`
  --background: var(--gray-08);
  --background-fx: var(--gray-08-90);

  --inverted-on-surface: var(--gray-08);
  --inverted-surface: var(--gray-84);

  --on-surface: var(--gray-99);
  --on-surface-semi: var(--gray-99-15);
  --on-surface-semi-var: var(--gray-99-10);
  --on-surface-var: var(--gray-99-75);
  --on-surface-var-2: var(--gray-99-50);
  --outline: var(--gray-99-20);
  --outline-var: var(--gray-99-10);
  // Transition Layer
  --state-active-on-surface: var(--on-surface-semi);
  --state-hover-on-surface: var(--on-surface-semi-var);

  --surface-container-high: var(--gray-24);
  --surface-container-high-fx: var(--gray-24-90);
  --surface-container-low: var(--gray-11);
  --surface-container-medium: var(--gray-16);
  --surface-container-medium-fx: var(--gray-16-90);

  --primary-accent: var(--screamin-green-90);
  --primary-accent-container: var(--screamin-green-08);
  --primary-accent-surface-container: ???;
  --primary-accent-semi: var(--screamin-green-90-15);
  --primary-accent-semi-var: var(--screamin-green-90-10);
  --primary-on-accent: var(--screamin-green-08);
  --primary-on-accent-container: var(--screamin-green-99);
  --primary-on-accent-container-semi: var(--screamin-green-99-10);
  --primary-on-accent-container-var: var(--screamin-green-99-75);
  --primary-on-accent-container-var-2: var(--screamin-green-99-50);
  --primary-on-accent-semi: var(--screamin-green-08-10);

  --tertiary-accent: var(--sunglow-82);
  --tertiary-accent-container: var(--sunglow-08);
  --tertiary-accent-semi: var(--sunglow-82-15);
  --tertiary-accent-semi-var: var(--sunglow-82-10);
  --tertiary-on-accent: var(--sunglow-08);
  --tertiary-on-accent-container: var(--sunglow-99);
  --tertiary-on-accent-container-semi: var(--sunglow-99-10);
  --tertiary-on-accent-container-var: var(--sunglow-99-75);
  --tertiary-on-accent-container-var-2: var(--sunglow-99-50);
  --tertiary-on-accent-semi: var(--sunglow-08-10);

  --error-error: var(--red-orange-60);
  --error-error-container: var(--red-orange-10);
  --error-on-error: var(--red-orange-10);
  --error-on-error-container: var(--red-orange-99);
  --error-on-error-surface-semi: var(--red-orange-99-10);

  --fg-1: var(--on-surface);
  --fg-2: var(--on-surface-var);
  --fg-3: var(--on-surface-var-2);
  --fg-4: var(--outline-var);

  --bg-4: var(--background);
  --bg-3: var(--surface-container-low);
  --bg-2: var(--surface-container-medium);
  --bg-1: var(--surface-container-high);
  --surface: rgba(255, 255, 255, 0.1);
`;

export const LIGHT_MODE_COLORS = `
  --background: var(--gray-84);
  --background-fx: var(--gray-84-90);

  --inverted-surface: var(--gray-08);
  --inverted-on-surface: var(--gray-99);

  --on-surface: var(--gray-08);
  --on-surface-semi: var(--gray-08-15);
  --on-surface-semi-var: var(--gray-08-10);
  --on-surface-var: var(--gray-08-75);
  --on-surface-var-2: var(--gray-08-50);
  --outline: var(--gray-08-20);
  --outline-var: var(--gray-08-10);
  --surface-container-high: var(--gray-99);
  --surface-container-high-fx: var(--gray-99-90);
  --surface-container-low: var(--gray-91);
  --surface-container-medium: var(--gray-96);
  --surface-container-medium-fx: var(--gray-96-90);

  // Transition Layer
  --state-active-on-surface: var(--on-surface-semi);
  --state-hover-on-surface: var(--on-surface-semi-var);

  --primary-accent: var(--midnight-green-30);
  --primary-accent-container: var(--midnight-green-99);
  --primary-accent-semi: var(--midnight-green-30-15);
  --primary-accent-semi-var: var(--midnight-green-30-10);
  --primary-on-accent: var(--midnight-green-99);
  --primary-on-accent-container: var(--midnight-green-08);
  --primary-on-accent-container-semi: var(--midnight-green-08-10);
  --primary-on-accent-container-var: var(--midnight-green-08-75);
  --primary-on-accent-container-var-2: var(--midnight-green-08-50);
  --primary-on-accent-semi: var(--midnight-green-99-10);

  --tertiary-accent: var(--bonbon-60);
  --tertiary-accent-container: var(--bonbon-99);
  --tertiary-accent-semi: var(--bonbon-60-15);
  --tertiary-accent-semi-var: var(--bonbon-60-10);
  --tertiary-on-accent: var(--bonbon-99);
  --tertiary-on-accent-container: var(--bonbon-08);
  --tertiary-on-accent-container-semi: var(--bonbon-08-10);
  --tertiary-on-accent-container-var: var(--bonbon-08-75);
  --tertiary-on-accent-container-var-2: var(--bonbon-08-50);
  --tertiary-on-accent-semi: var(--bonbon-99-10);

  --fg-1: var(--on-surface);
  --fg-2: var(--on-surface-var);
  --fg-3: var(--on-surface-var-2);
  --fg-4: var(--outline-var);

  --bg-4: var(--background);
  --bg-3: var(--surface-container-low);
  --bg-2: var(--surface-container-medium);
  --bg-1: var(--surface-container-high);

  --error-error: var(--red-orange-50);
  --error-error-container: var(--red-orange-99);
  --error-on-error: var(--red-orange-99);
  --error-on-error-container: var(--red-orange-10);
  --error-on-error-container-semi: var(--red-orange-10-10);

  --surface: rgba(255, 255, 255, 0.50);

`;

export const errorThemes = {
  error: css`
    --error: var(--error-error);
    --error-surface-container: var(--error-error-surface-container);
    --error-container: var(--error-error-surface-container);
    --on-error-surface: var(--error-on-error-surface);
  `,
};

export const accentThemes = {
  primary: css`
    --accent: var(--primary-accent);
    --accent-container: var(--primary-accent-container);
    --accent-semi: var(--primary-accent-semi);
    --accent-semi-var: var(--primary-accent-semi-var);
    --on-accent: var(--primary-on-accent);
    --on-accent-container: var(--primary-on-accent-container);
    --on-accent-container-semi: var(--primary-on-accent-container-semi);
    --on-accent-container-var: var(--primary-on-accent-container-var);
    --on-accent-container-var-2: var(--primary-on-accent-container-var-2);
    --on-accent-semi: var(--primary-on-accent-semi);

    --accent-surface-container: var(--accent-container);
    --on-accent-surface: var(--on-accent-container);
    --on-accent-surface-var: var(--on-accent-container-var);
    --on-accent-surface-var-2: var(--on-accent-container-var-2);
    --state-active-accent: var(--accent-semi);
    --state-hover-accent: var(--accent-semi-var);

  `,
  secondary: css`
    --accent: var(--primary-accent);
    --accent-container: var(--primary-accent-container);
    --accent-semi: var(--primary-accent-semi);
    --accent-semi-var: var(--primary-accent-semi-var);
    --on-accent: var(--primary-on-accent);
    --on-accent-container: var(--primary-on-accent-container);
    --on-accent-container-semi: var(--primary-on-accent-container-semi);
    --on-accent-container-var: var(--primary-on-accent-container-var);
    --on-accent-container-var-2: var(--primary-on-accent-container-var-2);
    --on-accent-semi: var(--primary-on-accent-semi);

    --accent-surface-container: var(--accent-container);
    --on-accent-surface: var(--on-accent-container);
    --on-accent-surface-var: var(--on-accent-container-var);
    --on-accent-surface-var-2: var(--on-accent-container-var-2);
    --state-active-accent: var(--accent-semi);
    --state-hover-accent: var(--accent-semi-var);
  `,
  tertiary: css`
    --accent: var(--tertiary-accent);
    --accent-container: var(--tertiary-accent-container);
    --accent-semi: var(--tertiary-accent-semi);
    --accent-semi-var: var(--tertiary-accent-semi-var);
    --on-accent: var(--tertiary-on-accent);
    --on-accent-container: var(--tertiary-on-accent-container);
    --on-accent-container-semi: var(--tertiary-on-accent-container-semi);
    --on-accent-container-var: var(--tertiary-on-accent-container-var);
    --on-accent-container-var-2: var(--tertiary-on-accent-container-var-2);
    --on-accent-semi: var(--tertiary-on-accent-semi);

    --accent-surface-container: var(--accent-container);
    --on-accent-surface: var(--on-accent-container);
    --on-accent-surface-var: var(--on-accent-container-var);
    --on-accent-surface-var-2: var(--on-accent-container-var-2);
    --state-active-accent: var(--accent-semi);
    --state-hover-accent: var(--accent-semi-var);
  `,
};
export const secondaryTheme = accentThemes.secondary;
export const primaryTheme = accentThemes.primary;
export const tertiaryTheme = accentThemes.tertiary;

export const darkTheme = DARK_MODE_COLORS;

export const baseTheme = css`
  ${baseColors}
  ${errorThemes.error}

  ${primaryTheme}

  --artist-hub: #b151c9;
  --music-distribution: #16ad40;
  --instant-mastering: #ffa800;

  --sub-amplify: #3cbeb1;
  --sub-amplify-plus: #926ef7;
  --sub-amplify-pro: #e4497d;

  --accent-green: #82bd32;
  --accent-orange: #fb9013;

  --state-positive: #00c82c;
  --state-negative: var(--error);

  --fg-inverted: var(--white-95);

  --bg-inverted: var(--gray-10);
`;
